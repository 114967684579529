@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}

.Navbar_navbar__2n6JJ{
    width: 100%;
    background: #effaf0;
    padding:20px 10px;
    box-sizing: border-box;

}

.Navbar_navbar__2n6JJ ul {
    display: flex;
    margin: 0 auto;
    max-width: 960px;
    align-items: center;
}

.Navbar_title__2N6UI{
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;


}

.Navbar_navbar__2n6JJ button, .Navbar_navbar__2n6JJ a {
    margin-left: 10px;
}

.Navbar_navbar__2n6JJ a{
    color:#333;
    text-decoration: none;
}

.Home_container__37ZFf {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width:960px;
    margin: 60px auto;
}

.Home_content__2ECSh {
    padding-right: 30px;
}

.Home_sidebar__2Fbg1{
    padding-left: 30px;
}

.Home_sidebar__2Fbg1 form {
    padding: 20px;
    background: #1f9751;
    border-radius: 10px;
}

.Home_sidebar__2Fbg1 input, .Home_sidebar__2Fbg1 select {
    display: block;
    width: 100%;
    padding:10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    color:#555;
    font-size: 1em;
}

.Home_sidebar__2Fbg1 label {
    margin: 0 auto 20px;
    display: block;
    color:#fff;
}

.Home_sidebar__2Fbg1 button {
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    cursor:pointer;
    display: block;
    width: 100%;

}

.Home_sidebar__2Fbg1 h3 {
    color: #1f9751;
    margin-bottom: 20px;

}


.Home_sidebar__2Fbg1 aside {
    margin-top:40px;
    color:#555;
}

.Home_sidebar__2Fbg1 aside li {
    margin: 10px;
}


/* transactions */

.Home_transactions__2JpcX li {
    margin: 30px auto;
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50,50,50,0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    position:relative;
    overflow: hidden;
    border-left:4px solid #1f9751;

}

.Home_transactions__2JpcX .Home_name__2zwVF {
    color: #777;
    font-size: 1.4em;
}

.Home_transactions__2JpcX .Home_amount__88AY4 {
    margin-left: auto;
    margin-right: 40px;
    color: #777;
    font-weight: bold;
    font-size: 1.6em;

}

.Home_transactions__2JpcX button {
    position: absolute;
    top: 0;
    right: 0;
    background: #ddd;
    color: #777;
    border:none;
    padding: 12px 8px;
    text-align: center;
    line-height: 0;
    font-size: 0.9em;
    cursor: pointer;
}
.Login_login-form__8Q77m {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
}

.Login_login-form__8Q77m label {
    display: block;
    margin: 30px auto;
}

.Login_login-form__8Q77m span {
    display: block;
    margin-bottom: 6px;
}

.Login_login-form__8Q77m input {
    padding: 8px 6px;
    font-size: 1em;
    color:#777;
    width:100%;

}
.Signup_signup-form__3_f7m {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
}

.Signup_signup-form__3_f7m label {
    display: block;
    margin: 30px auto;
}

.Signup_signup-form__3_f7m span {
    display: block;
    margin-bottom: 6px;
}

.Signup_signup-form__3_f7m input {
    padding: 8px 6px;
    font-size: 1em;
    color:#777;
    width:100%;

}
