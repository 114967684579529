.navbar{
    width: 100%;
    background: #effaf0;
    padding:20px 10px;
    box-sizing: border-box;

}

.navbar ul {
    display: flex;
    margin: 0 auto;
    max-width: 960px;
    align-items: center;
}

.title{
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;


}

.navbar button, .navbar a {
    margin-left: 10px;
}

.navbar a{
    color:#333;
    text-decoration: none;
}
